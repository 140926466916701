<template>
  <v-container fluid>
    <base-page-title
      >Configure DUT: Fixture Design {{ partNumber }} - Device Under Test
      {{ dutConfig.name }}</base-page-title
    >
    <v-toolbar v-if="stateDescription" dense flat>
      <div>
        Fixture Design State:
        {{ fixtureStateName }}
        - {{ stateDescription }}
      </div>
    </v-toolbar>
    <div v-if="isLoading" />
    <DutDetailsPage
      v-else
      ref="dut_config_form"
      :calledFromQuote="false"
      :editMode="true"
      :dutIndex="0"
      :quote="quote"
      :dutDetails="dutDetails"
      :dutFieldDescriptions="dutFieldDescriptions"
      :isAdmin="isAdmin"
      :project="project"
      :currentGerbers="gerbers"
      :dutConfig="dutConfig"
      :isInitialState="isInitialState"
      :parsedJsonBlob="parsedJsonBlob"
      :parsedTestPoints="parsedTestPoints"
      :parsedGuidePins="parsedGuidePins"
      :parsedPressurePins="parsedPressurePins"
      :parsedHoles="parsedHoles"
      :parsedDutData="parsedDutData"
      :parsedMissingHeaders="parsedMissingHeaders"
      :testpointLayers="testpointLayers"
      :testpointTypes="testpointTypes"
      :pcbStackup="Object.keys(pcbStackup).length > 0 ? pcbStackup : blankSvg"
      :dutUnits="dutUnits"
      :isQuoteIncomplete="isInitialState"
      @saveData="saveForm"
      @backToPrevious="backToPrevious"
      @onSaveDutFileData="saveDutFileData"
      @onSaveAllDutData="saveAllDutData"
      @onSaveGerberFiles="saveGerberFiles"
      @scrollToTop="onScrollToTop"
      @deleteCurrentGerbers="deleteAllGerbersHandler"
      @deleteCurrentDut="deleteAllDut"
      @downloadGerber="downloadGerberHandler"
      @parseTestPoint="parseTestPoint"
      @onSaveParsedDUT="saveParsedDUT"
    />
  </v-container>
</template>
<script>
import pcbStackup from "pcb-stackup";
import {
  fileDownloader,
  getFileString,
  gerbersToZip,
} from "@/helpers/basehelper.js";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DutConfigEdit",
  components: {
    DutDetailsPage: () => import("@/modules/quotes/views/DutDetailsPage.vue"),
  },
  data() {
    return {
      confirmAction: "",
      confirmMessage: "",
      selected: null,
      isLoading: false,
      DEV_FIXTURE_DEFAULT_VOLUME: 500,
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: "auth/isAdmin",
      project: "projects/selProject",
      dutConfig: "dutconfigs/dutConfigs",
      gerbers: "dutconfigs/gerberFiles",
      parsedJsonBlob: "dutconfigs/parsedJsonBlob",
      parsedTestPoints: "dutconfigs/parsedTestPoints",
      parsedGuidePins: "dutconfigs/parsedGuidePins",
      parsedPressurePins: "dutconfigs/parsedPressurePins",
      parsedHoles: "dutconfigs/parsedHoles",
      parsedDutData: "dutconfigs/parsedDutData",
      parsedMissingHeaders: "dutconfigs/parsedMissingHeaders",
      testpointLayers: "dutconfigs/testpointLayers",
      testpointTypes: "dutconfigs/testpointTypes",
      dutUnits: "dutconfigs/dutUnits",
      pcbStackup: "dutconfigs/pcbStackup",
      dutFieldDescriptions: "dutconfigs/dutFieldDescriptions",
      blankSvg: "dutconfigs/blankSvg",
      fixtureDesign: "fixturedesign/fixtureDesign",
      fdState: "fixturedesign/fdState",
      quote: "dutconfigs/quote",
      dutDetails: "dutconfigs/dutDetails",
      fdStateLongList: "ui/fdStateLongList",
    }),
    fixtureDesignId() {
      return Object.keys(this.project.fixture_design_details)[0];
    },
    isInitialState() {
      return this.fixtureDesign.state === 1;
    },
    fixtureState() {
      return this.fixtureDesign.state;
    },
    fixtureStateName() {
      const result = this.fdState.find(
        (element) => element.pk === this.fixtureDesign.state
      );
      return result ? result.description : "";
    },
    stateDescription() {
      const result = this.fdStateLongList.find(
        (element) => element.pk === this.fixtureDesign.state
      );
      return result ? result.description : null;
    },
    partNumber() {
      return this.fixtureDesign.part ? this.fixtureDesign.part.number : "";
    },
    zippedGerberContainer() {
      return this.dutConfig?.zipped_gerber_files
        ? {
            url: this.dutConfig.zipped_gerber_files,
            file: this.dutConfig.zipped_gerber_files.split("/").pop(),
          }
        : null;
    },
  },
  methods: {
    fileDownloader,
    getFileString,
    gerbersToZip,
    ...mapActions({
      createDutConfig: "dutconfigs/saveDutConfigs",
      patchDutConfig: "dutconfigs/updateDutConfigs",
      updateDutConfig: "dutconfigs/updateDutConfigById",
      deleteGerberFile: "dutconfigs/removeGerberById",
      deleteAllGerberFile: "dutconfigs/removeAllGerberFile",
      getDutConfigs: "dutconfigs/getDutConfigsById",
      convertTestPoint: "dutconfigs/parseTestPointFile",
      patchBulkGerbers: "dutconfigs/patchBulkGerbers",
      saveTestPointsBlob: "dutconfigs/saveTestPointsBlob",
      patchFixtureDesign: "fixturedesign/patchFixtureDesign",
      initFixtureDesignState: "fixturedesign/initFixtureDesignState",
      initSelectedProject: "projects/clearProject",
      saveQuote: "dutconfigs/saveQuote",
      saveDutDetails: "dutconfigs/saveDutDetail",
      toggleLoading: "ui/loading",
    }),
    async saveForm({ configData, detailData, blobs }) {
      console.log(configData, detailData, blobs);
      const { testpoints, guidepins, pressurepins } = blobs;
      console.log("From DutConfigEdit", testpoints, guidepins, pressurepins);
      try {
        const formPayload = {
          pk: this.dutConfig.pk,
          data: configData,
        };
        this.toggleLoading("Updating DUT Details");
        await this.patchDutConfig(formPayload);
        this.toggleLoading();
        await this.saveAllDutData(detailData);
        this.toggleLoading("Updating Config Data");
        await Promise.all([
          this.saveAllTestPoints(testpoints),
          this.saveAllGuidePins(guidepins),
          this.saveAllPressurePins(pressurepins),
        ]);
        this.toggleLoading();
        this.$router.replace({
          name: "configure",
          params: { id: this.$route.params.id, did: this.dutConfig.pk },
        });
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveAllTestPoints(testpoints) {
      try {
        const tpData = {
          test_points: testpoints,
        };
        console.log("TP Blob Patch Payload", testpoints, tpData);
        await this.saveTestPointsBlob({
          project_id: this.project.pk,
          dut_id: this.dutConfig.pk,
          data: tpData,
          data_type: "test-points",
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveAllGuidePins(guidepins) {
      try {
        const gpData = {
          guide_pins: guidepins,
        };
        await this.saveTestPointsBlob({
          project_id: this.project.pk,
          dut_id: this.dutConfig.pk,
          data: gpData,
          data_type: "guide-pins",
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveAllPressurePins(pressurepins) {
      try {
        const ppData = {
          pressure_pins: pressurepins,
        };
        await this.saveTestPointsBlob({
          project_id: this.project.pk,
          dut_id: this.dutConfig.pk,
          data: ppData,
          data_type: "pressure-pins",
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveGerberFiles({ gerbers, zipped_file }) {
      console.log("DutConfigEdit", zipped_file);
      try {
        this.isLoading = true;
        if (gerbers.gerberFiles.length > 0) {
          this.toggleLoading("Saving Gerber Files");
          let gerberPayload = new FormData();
          gerberPayload.append("user", this.dutConfig.user);
          gerberPayload.append("fixture_design", this.fixtureDesignId);
          for (let i = 0; i < gerbers.gerberFiles.length; i++) {
            gerberPayload.append(
              "files",
              new Blob([gerbers.gerberFiles[i].gerber], {
                type: "application/vnd.gerber",
              }),
              gerbers.gerberFiles[i].file
            );
          }
          console.log("New Gerber Endpoint", gerbers, gerberPayload);
          await this.patchBulkGerbers({
            project_id: this.project.pk,
            dut_id: this.dutConfig.pk,
            data: gerberPayload,
          });
          await this.updateDutConfig({
            pk: this.dutConfig.pk,
            data: await this.gerbersToZip(gerbers.gerberFiles),
          });
          if (this.gerbers) await this.saveGerbersSvg(this.dutConfig.pk);
          this.toggleLoading();
        }
        // Post Quote and DD if no matching Quote on FD
        if (!this.fixtureDesign.quote) {
          await this.createQuoteAndDutDetails({ zipped_file });
        } else {
          await this.saveDutFileData({
            id: this.dutConfig.dut_details,
            key: "gerber_zip",
            value: zipped_file,
          });
        }
        this.isLoading = false;
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveParsedDUT({ testpoint_file, dut_settings, parsed_json_blob }) {
      // Post Quote and DD if no matching Quote on FD
      if (!this.fixtureDesign.quote) {
        await this.createQuoteAndDutDetails({
          testpoint_file,
          dut_settings,
          parsed_json_blob,
        });
      }
    },
    async createQuoteAndDutDetails({
      zipped_file = null,
      testpoint_file = null,
      dut_settings = null,
      parsed_json_blob = {},
    }) {
      const actionPayload = {
        editMode: false,
        id: null,
        data: {
          user: this.$store.getters["auth/user"].pk,
          project_name: this.project.project_name,
          estimated_volumes: this.DEV_FIXTURE_DEFAULT_VOLUME,
          added_from_create_project_view: true,
        },
      };
      try {
        this.toggleLoading("Saving Quote");
        const response = await this.saveQuote(actionPayload);
        let dutData = new FormData();
        dutData.append("user", response.user);
        dutData.append("quote", response.id);
        dutData.append("dut_name", this.dutConfig.name);
        dutData.append("dut_file_data", JSON.stringify(parsed_json_blob));
        if (zipped_file) dutData.append("gerber_zip", zipped_file);
        if (testpoint_file) dutData.append("test_point_file", testpoint_file);
        if (dut_settings) {
          dutData.append("units", dut_settings.units);
          dutData.append("x_origin", dut_settings.x_origin);
          dutData.append("y_origin", dut_settings.y_origin);
          dutData.append("pcb_width", dut_settings.dut_width);
          dutData.append("pcb_height", dut_settings.dut_length);
          dutData.append("pcb_thickness", dut_settings.dut_height);
        }
        const dutDetailsPayload = {
          editMode: false,
          id: null,
          data: dutData,
        };
        const response_detail = await this.saveDutDetails(dutDetailsPayload);
        let formData = new FormData();
        formData.append("quote", response.id);
        let ddData = new FormData();
        ddData.append("dut_details", response_detail.id);
        await this.updateDutConfig({
          pk: this.dutConfig.pk,
          data: ddData,
        });
        this.toggleLoading();
        await this.patchFixtureDesign({
          pk: this.fixtureDesignId,
          data: formData,
        });
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveGerbersSvg(pk) {
      try {
        const layers = await Promise.all(
          this.gerbers.map(async (element) => ({
            filename: element.file,
            gerber: await this.getFileString(element.file),
          }))
        );
        const response = await pcbStackup(layers);
        const actionPayload = {
          pk,
          data: { pcb_stackup: JSON.stringify(response) },
        };
        await this.patchDutConfig(actionPayload);
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    backToPrevious() {
      this.$router.push({ name: "projects" });
    },
    async deleteGerberHandler() {
      try {
        await this.deleteGerberFile({
          pk: this.selected,
          dutPk: this.dutConfig.pk,
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async deleteAllGerbersHandler() {
      this.toggleLoading("Deleting Gerbers and all relevant data");
      await this.deleteAllGerbers();
      try {
        let configData = new FormData();
        configData.append("pcb_stackup", "");
        configData.append("zipped_gerber_files", new File([], ""));
        const configPayload = {
          pk: this.dutConfig.pk,
          data: configData,
        };
        let detailsData = new FormData();
        detailsData.append("gerber_zip", new File([], ""));
        const detailsPayload = {
          editMode: true,
          id: this.dutConfig.dut_details,
          data: detailsData,
        };
        console.log(configPayload);
        await this.updateDutConfig(configPayload);
        await this.saveDutDetails(detailsPayload);
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
      this.toggleLoading();
    },
    async deleteAllGerbers() {
      try {
        let gerberPayload = new FormData();
        gerberPayload.append("user", this.dutConfig.user);
        gerberPayload.append("fixture_design", this.fixtureDesignId);

        await this.patchBulkGerbers({
          project_id: this.project.pk,
          dut_id: this.dutConfig.pk,
          data: gerberPayload,
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    downloadGerberHandler({ url, file }) {
      this.toggleLoading("Downloading, one moment please");
      this.fileDownloader({ url, file });
      this.toggleLoading();
    },
    async createAndDownloadGerber(payload) {
      this.toggleLoading("Creating zip file for the first time");
      const gerbers = await Promise.all(
        payload.map(async (element) => ({
          file: element.file,
          gerber: await this.getFileString(element.url),
        }))
      );
      await this.updateDutConfig({
        pk: this.dutConfig.pk,
        data: await this.gerbersToZip(gerbers),
      });
      this.toggleLoading();
      this.downloadGerberHandler(this.zippedGerberContainer);
    },
    async parseTestPoint({ payload }) {
      console.log("Quote Template", payload);
      try {
        this.toggleLoading("Parsing");
        await this.convertTestPoint(payload);
        this.$refs[`dut_config_form`] &&
          this.$refs[`dut_config_form`].updateConfig2d();
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
        this.$refs[`dut_config_form`] &&
          this.$refs[`dut_config_form`].initTestPointFile();
      }
    },
    async saveAllDutData(payload) {
      try {
        this.toggleLoading("Updating DUT Details");
        await this.saveDutDetails(payload);
        this.toggleLoading();
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveDutFileData({ id, key, value }) {
      let inputData = new FormData();
      inputData.append(key, value);

      const actionPayload = {
        editMode: true,
        id,
        data: inputData,
      };

      try {
        this.toggleLoading("Saving File");
        await this.saveDutDetails(actionPayload);
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    onScrollToTop() {
      this.$vuetify.goTo(0);
    },
    async deleteAllDut() {
      try {
        this.toggleLoading("Removing DUT file");
        let detailsData = new FormData();
        detailsData.append("test_point_file", new File([], ""));
        detailsData.append("units", 1);
        detailsData.append("x_origin", 0);
        detailsData.append("y_origin", 0);
        detailsData.append("pcb_width", "");
        detailsData.append("pcb_height", "");
        detailsData.append("pcb_thickness", 1.6);
        detailsData.append("dut_file_data", "{}");
        const detailsPayload = {
          editMode: true,
          id: this.dutConfig.dut_details,
          data: detailsData,
        };
        await this.saveDutDetails(detailsPayload);
        this.$refs[`dut_config_form`] &&
          this.$refs[`dut_config_form`].initDutFields();
        this.toggleLoading();
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
  },
};
</script>
